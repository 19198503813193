import React, { useCallback, useEffect, useState } from 'react';
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import { Row, Col } from 'react-bootstrap';
import Separator from '~/components/Separator';
import { InputDate, InputNumber2, InputSelect } from '~/components/NovosInputs';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { CancelButton, ConfirmButton } from '~/components/Buttons';
import { useClienteContext } from '../../ClienteContext';
import { GoPencil } from 'react-icons/go';
import { MdCheck, MdClose, MdDeleteForever } from 'react-icons/md';
import { ButtonRow, FildsetContainer, Legend } from '../../styles';
import { deleteItens } from '~/utils/masterDetail';
import { SelectType } from '~/components/NovosInputs/InputSelect/protocols';
import ToggleDefault from '~/components/ToggleDefault';
import moment from 'moment';

export const AbaAdministrativo: React.FC = () => {
  const [page, setPage] = useState<number>(0);

  const {
    registerAdmin,
    controlAdmin,
    setValueAdmin,
    watchAdmin,
    setErrorAdmin,
    clearErrorsAdmin,
    formStateAdmin,
    handleAdministrativo,
    masterDetail,
    setMasterDetail,
    modulos,
    setModulos,
    modulosAEditar,
    setModulosAEditar,
    flgDemonstracao,
    setFlgDemonstracao,
    flgAtivo,
    setFlgAtivo,
    modulosOptions,
    aplicacoesData,
    handleClearAdministrativo,
  } = useClienteContext();

  const colunasModulos: GridColumns = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'des_modulo',
      headerName: 'Modulo',
      type: 'string',
      flex: 2,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'flg_ativo',
      headerName: 'Ativo?',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <>
            {row.flg_ativo ? (
              <MdCheck size={25} color="green" />
            ) : (
              <MdClose color="red" size={25} />
            )}
          </>
        );
      },
    },
    {
      field: 'flg_demonstracao',
      headerName: 'Demonstração?',
      flex: 2,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <>
            {row.flg_demonstracao ? (
              <MdCheck size={25} color="green" />
            ) : (
              <MdClose color="red" size={25} />
            )}
          </>
        );
      },
    },
    {
      field: 'dta_inicio',
      headerName: 'Inicio Demo.',
      flex: 2,
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <>
            {row.flg_demonstracao &&
              moment(row.dta_inicio).utc().format('DD/MM/YYYY')}
          </>
        );
      },
    },
    {
      field: 'dta_final',
      headerName: 'Fim Demo.',
      flex: 2,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <>
            {row.flg_demonstracao &&
              moment(row.dta_final).utc().format('DD/MM/YYYY')}
          </>
        );
      },
    },
    {
      field: '',
      headerName: 'Ações',
      width: 180,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      renderCell: (params: { row: any }) => {
        const onEdit = () => {
          const { row } = params;
          setValueAdmin('aplicacao', {
            label: row.des_aplicacao,
            value: row.cod_aplicacao,
          });
          setValueAdmin('cod_modulo', {
            value: row.cod_modulo,
            label: row.des_modulo,
          });
          setValueAdmin('flg_ativo', row.flg_ativo);
          setValueAdmin('flg_demonstracao', row.flg_demonstracao);
          setFlgAtivo(row.flg_ativo);
          setFlgDemonstracao(row.flg_demonstracao);
          setFlgAtivo(row.flg_ativo);
          setFlgDemonstracao(row.flg_demonstracao);
          setModulosAEditar({
            uuid: row.uuid,
            cod_seq_modulo: row.cod_seq_modulo,
          });

          row.flg_demonstracao &&
            setValueAdmin(
              'dta_inicio',
              moment(row.dta_inicio).utc().format('YYYY-MM-DD'),
            );
          row.flg_demonstracao &&
            setValueAdmin(
              'dta_final',
              moment(row.dta_final).utc().format('YYYY-MM-DD'),
            );

          const codLoja = watchAdmin('cod_local');
          const aplicacao = watchAdmin('aplicacao');
          if (codLoja && aplicacao) {
            const elm = document.getElementById('collapseThreeModulo');
            if (elm) {
              elm.removeAttribute('class');
              elm.classList.add('accordion-collapse', 'collapse', 'show');
            }
          }
        };
        const onDelete = () => {
          const { row } = params;
          handleRemoveModulo(row.uuid);
        };

        return (
          <>
            <ButtonRow type="button" onClick={onEdit}>
              <GoPencil size={20} style={{ color: '#72ab90' }} /> Editar
            </ButtonRow>
            <ButtonRow type="button" onClick={onDelete}>
              <MdDeleteForever size={20} style={{ color: '#e63c3c' }} /> Excluir
            </ButtonRow>
          </>
        );
      },
    },
  ];
  const handleRemoveModulo = useCallback(
    async (uuid) => {
      const autorizadosDetail: any[] = await deleteItens(
        'modulo',
        uuid,
        masterDetail,
        setMasterDetail,
      );
      setModulos(autorizadosDetail);
    },
    [masterDetail],
  );

  const handlePasteInputNumber = (
    event: React.ClipboardEvent<HTMLInputElement>,
  ) => {
    const { clipboardData } = event;
    const pastedText = clipboardData.getData('text');
    if (pastedText.search('^[0-9]+$')) {
      event.preventDefault();
    }
  };

  /**
   * @description Função que verifica se o valor digitado
   * no input tem o valor de "-" ou "e"(número de euller).
   *  */
  const handleKeyDown = (event: any) => {
    if (
      [69, 109, 107, 194, 110, 190, 187, 188, 189, 190].includes(event.keyCode)
    ) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const codLoja = watchAdmin('cod_local');
    const aplicacao = watchAdmin('aplicacao');
    if (!codLoja || !aplicacao) {
      const elm = document.getElementById('collapseThreeModulo');
      if (elm) {
        elm.removeAttribute('class');
        elm.classList.add('accordion-collapse', 'collapse');
      }
    }
  }, [watchAdmin('cod_local'), watchAdmin('aplicacao')]);
  return (
    <div className="row" style={{ marginBottom: '60px' }}>
      <Col sm={12} md={3}>
        <InputSelect
          register={registerAdmin}
          control={controlAdmin}
          placeholder="Selecione..."
          name="aplicacao"
          label="Aplicação"
          isError={false}
          changeSelected={function (selected: SelectType) {
            setValueAdmin('aplicacao', selected);
          }}
          options={aplicacoesData}
        />
      </Col>
      <Col sm={12} md={3}>
        <InputNumber2
          register={registerAdmin}
          control={controlAdmin}
          name="cod_local"
          label="Cód. Local(Código da Loja Local)"
          placeholder="0"
          isError={false}
          min={0}
          onPaste={(e) => handlePasteInputNumber(e)}
          onKeyDown={handleKeyDown}
          max={99999}
          maxLength={5}
        />
      </Col>
      <Col sm={12}>
        <Separator labelText="Módulos do Cliente" childrenWidth="200px">
          <button
            type="button"
            className="btnLimiteCredito"
            data-bs-toggle="collapse"
            data-bs-target="#collapseThreeModulo"
            aria-expanded="false"
            aria-controls="collapseThreeModulo"
            style={{
              marginTop: '-7px',
              backgroundColor: '#F4F5F7',
            }}
            disabled={!watchAdmin('cod_local') || !watchAdmin('aplicacao')}
          >
            <AiOutlinePlusCircle
              size={16}
              style={{ marginTop: '-2px', color: 'green' }}
            />{' '}
            Adicionar novo Módulo
          </button>
        </Separator>
      </Col>
      {/* Inicio accordion */}
      <div
        className="accordion"
        id="accordionModulo"
        style={{
          marginBottom: '0.938rem',
          padding: '0px',
          marginLeft: '-0.45rem',
        }}
      >
        <div
          id="collapseThreeModulo"
          className="accordion-collapse collapse"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionModulo"
        >
          <div className="accordion-body">
            <Row>
              <Col
                sm={12}
                md={3}
                style={{ marginTop: '0.4rem' }}
                className="finalizadoraContainer"
              >
                <InputSelect
                  label="Módulo"
                  name="cod_modulo"
                  placeholder="Selecione..."
                  register={registerAdmin}
                  isError={!!formStateAdmin.errors.cod_modulo}
                  control={controlAdmin}
                  options={modulosOptions}
                  changeSelected={(selected) => {
                    clearErrorsAdmin('cod_modulo');
                    setValueAdmin('cod_modulo', selected);
                  }}
                />
              </Col>

              <Col sm={12} md={1} style={{ marginTop: '0.1rem' }}>
                <ToggleDefault
                  labelText="Ativo?"
                  setChecked={flgAtivo}
                  onSwitch={() => {
                    setValueAdmin('flg_ativo', !flgAtivo);
                    setFlgAtivo(!flgAtivo);
                  }}
                />
              </Col>
              <Col sm={12} md={1} style={{ marginTop: '-0.13rem' }}>
                <ToggleDefault
                  labelText="Demonstração?"
                  setChecked={flgDemonstracao}
                  onSwitch={() => {
                    setValueAdmin('flg_demonstracao', !flgDemonstracao);
                    setFlgDemonstracao(!flgDemonstracao);
                    setValueAdmin('dta_inicio', '');
                    setValueAdmin('dta_final', '');
                  }}
                />
              </Col>
              {flgDemonstracao && (
                <Col sm={12} md={4}>
                  <FildsetContainer>
                    <Legend>Demonstração</Legend>
                    <Col
                      style={{ marginTop: '-0.3rem', marginRight: '0.3rem' }}
                    >
                      <InputDate
                        name="dta_inicio"
                        register={registerAdmin}
                        control={controlAdmin}
                        isError={!!formStateAdmin.errors.dta_inicio}
                        label="Inicio"
                      />
                    </Col>
                    <Col style={{ marginTop: '-0.3rem', marginLeft: '0.3rem' }}>
                      <InputDate
                        name="dta_final"
                        register={registerAdmin}
                        control={controlAdmin}
                        isError={!!formStateAdmin.errors.dta_final}
                        label="Fim"
                      />
                    </Col>
                  </FildsetContainer>
                </Col>
              )}
              <Col
                sm={12}
                md={3}
                className="buttonContainer"
                style={{ paddingRight: '0px' }}
              >
                <div className="actButtonsAdmin">
                  <ConfirmButton
                    style={{ height: '35px', marginLeft: '0.5rem' }}
                    onClick={() => {
                      handleAdministrativo();
                    }}
                  >
                    {modulosAEditar ? 'Editar' : 'Adicionar'}
                  </ConfirmButton>
                  <CancelButton
                    style={{ height: '35px', marginTop: '0.5rem' }}
                    onClick={handleClearAdministrativo}
                  >
                    Cancelar
                  </CancelButton>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      {/* fim accordion */}
      <Col sm={12} className="dataTable" style={{ height: 280, width: '100%' }}>
        <Col
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            marginBottom: '50px',
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              className="myDataGrid"
              rows={modulos}
              columns={colunasModulos}
              autoHeight
              sortModel={[
                {
                  field: 'des_modulo',
                  sort: 'asc',
                },
              ]}
              columnBuffer={8}
              disableColumnFilter
              disableColumnSelector
              disableSelectionOnClick
              disableColumnMenu
              pageSize={4}
              onPageChange={(newPage) => setPage(newPage)}
              page={page}
              localeText={{
                noRowsLabel: 'Nenhum registro encontrado',
                columnMenuLabel: 'Menu',
                columnMenuFilter: 'Filtrar',
                columnMenuHideColumn: 'Esconder',
                columnMenuUnsort: 'Não ordenar',
                columnMenuSortAsc: 'Ordernar ASC',
                columnMenuSortDesc: 'Ordernar DESC',
                columnMenuShowColumns: 'Mostrar colunas',
              }}
            />
          </div>
        </Col>
      </Col>
    </div>
  );
};
