import React from 'react';
import { ClienteProvider } from './ClienteContext';
import ClienteContent from './ClienteContent';

const Cliente: React.FC = () => {
  return (
    <ClienteProvider>
      <ClienteContent />
    </ClienteProvider>
  );
};

export default Cliente;
