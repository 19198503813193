export const tiposDePessoa = [
  {
    label: 'Física',
    value: 0,
  },
  {
    label: 'Jurídica',
    value: 1,
  },
];

export const defaultOptions = {
  sexo: [
    {
      label: 'Masculino',
      value: 0,
    },
    {
      label: 'Feminino',
      value: 1,
    },
  ],
  ativo: [
    {
      label: 'Ativo',
      value: 0,
    },
    {
      label: 'Inativo',
      value: 1,
    },
  ],
};

export const tiposOrgaoPublico = [
  {
    label: 'Federal',
    value: 0,
  },
  {
    label: 'Estadual',
    value: 1,
  },
  {
    label: 'Municipal',
    value: 2,
  },
];

export const aplicacoes = [
  {
    value: null,
    label: 'NENHUM',
  },
];

export const parentescos = [
  {
    value: 0,
    label: 'Cônjuge',
  },
  {
    value: 1,
    label: ' Filho(a)',
  },
  {
    value: 2,
    label: ' Pais',
  },
  {
    value: 3,
    label: ' Outro',
  },
];
