import { useQuery, UseQueryResult } from 'react-query';
import api from '~/services/api';

export const useClassificacaoParceiro = (
  tipo: number,
): UseQueryResult<any, unknown> => {
  return useQuery(
    ['classificacao-parceiro', tipo],
    async () => {
      const { data } = await api.get(`/classificacao-parceiro/${tipo}`);
      if (data.success) {
        return data.data.map(
          (cond: { cod_classificacao: number; des_classificacao: string }) => ({
            label: cond.des_classificacao,
            value: cond.cod_classificacao,
          }),
        );
      }
      throw new Error('Erro ao buscar classificações');
    },
    {
      enabled: tipo !== undefined,
      refetchOnWindowFocus: true,
    },
  );
};
